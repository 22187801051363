.footer {
    position: fixed;
    bottom: 0;
    height: 72px;
    border-top: 1px solid #EAEAEA;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
}

.buttonMargin {
    margin-left: 8px;
}